//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import IconCross from '~/assets/img/icons/cross.svg?inline';
import IconCrossMenu from '~/assets/img/icons/cross-menu.svg?inline';

export default {
  name: 'UiProjectsFilter',
  components: {
    IconCross,
    IconCrossMenu,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Our projects',
    },
  },
  data() {
    return {
      menuActive: false,
      activeDropdownId: null,
    }
  },
  computed: {
    ...mapState('projects', ['allCategories']),
    menuList() {
      return this.list.filter(i => this.$route.query.categories !== i.uuid);
    },
    activeItem() {
      return this.allCategories.find(i => this.$route.query.categories === i.uuid);
    },
    activeDropdownItem() {
      return this.list.find(i => i.uuid === this.activeDropdownId);
    },
    activeDropdownItemName() {
      if (!this.activeDropdownItem) return '';

      const { name, content } = this.activeDropdownItem;
      return content.name_for_phone || content.name || name;
    },
    activeDropdownItemList() {
      if (!this.activeDropdownItem) return [];

      return this.activeDropdownItem.dropdown_list.filter(i => this.$route.query.categories !== i.uuid);
    },
  },
  methods: {
    handleMenuOpen() {
      this.handleDropdownClose();
      this.menuActive = true;
    },
    handleMenuClose() {
      this.menuActive = false;
    },
    handleDropdownToggle(uuid) {
      this.activeDropdownId = uuid === this.activeDropdownId ? null : uuid;
    },
    handleDropdownOpen(uuid) {
      this.activeDropdownId = uuid;
    },
    handleDropdownClose() {
      this.activeDropdownId = null;
    },
  },
};
