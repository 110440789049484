var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__body-item"},[_c('tippy',{attrs:{"followCursor":true,"flipOnUpdate":true,"arrow":false,"maxWidth":400,"placement":'bottom-end',"distance":20,"offset":10,"animateFill":false,"animation":"fade","touch":false,"flip":false,"delay":0,"duration":0},on:{"show":_vm.handleTippyShow,"hidden":_vm.handleTippyHide},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('BaseLinkWr',{staticClass:"table__body-item-wr -g-d-f",attrs:{"link":{
          linktype: 'story',
          cached_url: _vm.item.full_slug
        },"disabled":_vm.isDisabled}},[_c('div',{staticClass:"table__body-item-col table__col table__col--year"},[_c('div',{staticClass:"table__body-item-year -t1",domProps:{"innerHTML":_vm._s(_vm.year)}})]),_vm._v(" "),_c('div',{staticClass:"table__body-item-col table__col table__col--title"},[_c('div',{staticClass:"table__body-item-title -h1",domProps:{"innerHTML":_vm._s(_vm.content.title || _vm.item.name)}})]),_vm._v(" "),_c('div',{staticClass:"table__body-item-col table__col table__col--descr -g-d-if -g-fd-c -g-jc-sb -g-ai-fs"},[_c('div',{staticClass:"table__body-item-descr -t1 -ws-pl",domProps:{"innerHTML":_vm._s(_vm.content.description)}}),_vm._v(" "),(_vm.state && _vm.state.content.tag_title && !_vm.$device.isDesktop)?_c('div',{staticClass:"table__body-item-tag -t1"},[_vm._v(_vm._s(_vm.state.content.tag_title))]):_vm._e()])])]},proxy:true}])},[_vm._v(" "),(_vm.previewExist)?_c('div',{staticClass:"preview"},[(_vm.initSwiper)?_c('swiper',{staticClass:"preview__swiper",attrs:{"options":_vm.swiperOptions}},[(!_vm.state)?_vm._l((_vm.slides),function(item,idx){return _c('swiper-slide',{key:idx,staticClass:"preview__slide"},[(item && item.filename)?_c('img',{staticClass:"preview__slide-img swiper-lazy -img",attrs:{"src":_vm._f("sbImageService")(item.filename,'800x800'),"alt":item.alt,"width":"400","height":"400"}}):_vm._e()])}):_c('swiper-slide',{staticClass:"preview__slide"},[_c('div',{staticClass:"preview__slide-state -g-d-f -g-ai-c -g-jc-c",style:({
              color: _vm.state.content.text_color || null,
              backgroundColor: (_vm.state.content.background_color && _vm.state.content.background_color.color) || null,
              backgroundImage: _vm.state.content.background_image && _vm.state.content.background_image.filename ? ("url('" + (_vm.$options.filters.sbImageService(_vm.state.content.background_image.filename, '800x800')) + "')") : null,
            })},[(_vm.state.content.description)?_c('p',{staticClass:"-t1 -ws-pl",domProps:{"innerHTML":_vm._s(_vm.state.content.description)}}):_vm._e()])])],2):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }