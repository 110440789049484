var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-projects-filter-item",class:{
    'ui-projects-filter-item--active': _vm.isActive
  }},[_c('nuxt-link',{staticClass:"ui-projects-filter-item__link -g-d-if -g-ai-c -t1",attrs:{"to":{
      path: _vm.$route.path,
      query: _vm.isActive ? null : {
        categories: _vm.item.uuid,
      }
    },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){return _vm.handleClick($event, navigate)}}},[_c('span',[_vm._v(_vm._s(_vm.item.content.name || _vm.item.name))]),_vm._v(" "),_c('IconCross',{staticClass:"hide-mob"}),_vm._v(" "),_c('IconCrossMob',{staticClass:"show-mob-b"})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }