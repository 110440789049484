//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconCross from '~/assets/img/icons/cross.svg?inline';
import IconCrossMob from '~/assets/img/icons/cross-mob.svg?inline';

export default {
  name: 'UiProjectsFilterItem',
  components: {
    IconCross,
    IconCrossMob,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isActive() {
      return this.$route.query.categories === this.item.uuid;
    },
  },
  methods: {
    handleClick(e, navigate) {
      navigate(e);
      this.$emit('click', e);
    },
  },
};
