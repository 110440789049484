//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconCross from '~/assets/img/icons/cross.svg?inline';
import IconCrossMob from '~/assets/img/icons/cross-mob.svg?inline';

export default {
  name: 'UiProjectsFilterDropdownToggle',
  components: {
    IconCross,
    IconCrossMob,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    forMob: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
