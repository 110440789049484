//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapActions } from 'vuex';
import { useStoryblokBridge } from "@storyblok/nuxt-2";
import { getPathWithoutLocale, rewriteStorySlugs } from '~/utils/helpers';
import seoHead from '~/mixins/seo-head';

export default {
  name: 'Pages_',
  mixins: [seoHead],
  async asyncData({ route, query, isDev, i18n, error, store }) {
    const { _storyblok, test } = query;
    const { locale, defaultLocale } = i18n;
    const isPreview = !!(_storyblok && _storyblok !== '') || isDev;
    const isHome = true;

    const path = '/home';

    const filter_query = {};
    const filterCategories = query['categories'];

    if (filterCategories) {
      filter_query['categories'] = {
        any_in_array: filterCategories.split(',')[0], // filter only by one category
      };
    }

    try {
      const story = await store.dispatch('page/getPageStory', {
        path,
      });

      const categories = await store.dispatch('projects/getCategories');

      const projects = await store.dispatch('projects/getProjects', {
        filter_query,
      });

      const pagination = {
        page: 2,
        per_page: projects.perPage,
        total: projects.total
      };

      return {
        story,
        isHome,
        categories,
        projectsList: projects.data.stories,
        pagination,
        infiniteId: +new Date(),
      };
    } catch(err) {
      console.error(err);
      error(err);
    }
  },
  watch: {
    '$route.query.categories': {
      handler: function(search) {
        this.onFilterChange();
      },
    }
  },
  computed: {
    availableLocale() {
      return this.$i18n.locales.find(i => i.code !== this.$i18n.locale);
    },
    hasNextPage() {
      const projectsList = this.projectsList;
      const total = this.pagination.total;

      return projectsList && total && projectsList.length < total;
    },
    getSwitchPath() {
      const path = this.switchLocalePath(this.availableLocale.code);

      return rewriteStorySlugs[path] || path;
    }
  },
  mounted() {
    if (this.story?.id) {
      useStoryblokBridge(this.story.id, (newStory) => {
        this.story = newStory;
        this.setPageStory(newStory);
      });
    }
  },
  methods: {
    ...mapMutations('page', ['setPageStory']),
    ...mapActions('projects', ['getProjects']),
    getFilterQuery() {
      const { query } = this.$route;
      const filter_query = {};
      const filterCategories = query['categories'];

      if (filterCategories) {
        filter_query['categories'] = {
          any_in_array: filterCategories.split(',')[0], // filter only by one category
        };
      }

      return filter_query;
    },
    async onFilterChange() {
      const filter_query = this.getFilterQuery();
      const { per_page } = this.pagination;

      try {
        const projects = await this.getProjects({
          filter_query,
          page: 1,
          per_page,
        });

        this.pagination = {
          page: 2,
          per_page: projects.perPage,
          total: projects.total
        };

        this.projectsList = projects.data.stories;
        this.infiniteId += 1;
      } catch(err) {
        console.error({ err });
      }
    },
    async infiniteHandler($state) {
      if (!this.hasNextPage) {
        $state.loaded();
        $state.complete();

        return;
      }

      const filter_query = this.getFilterQuery();
      const { page, per_page } = this.pagination;

      try {
        const projects = await this.getProjects({
          filter_query,
          page,
          per_page,
        });

        this.pagination = {
          page: page + 1,
          per_page: projects.perPage,
          total: projects.total
        };

        if (projects.data.stories.length) {
          this.projectsList = [...this.projectsList, ...projects.data.stories];
          $state.loaded();
        } else {
          $state.loaded();
          $state.complete();
        }
      } catch(err) {
        $state.error();
        console.error({ err });
      }
    },
  },
  middleware({ route, store }) {
    const { fullPath } = route;

    store.commit('setBackHomeSlug', fullPath);
  },
}
